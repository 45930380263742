import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BlogCard from "./BlogCard"
import * as styles from "./blog.module.scss"
const BlogFooter = ({ heading }) => {
  const { posts, postsV2 } = useStaticQuery(getFooterPosts)

  let postArr = [...postsV2.edges, ...posts.edges]
  let recentPosts = postArr.slice(0, 2)

  return (
    <div className="section-padding">
      <h2>{heading}</h2>
      <div
        className={`container ${styles.blogCardGrid} ${
          recentPosts.length === 1
            ? styles.blogCardGridOne
            : recentPosts.length === 2
            ? styles.blogCardGridTwo
            : ""
        }`}
      >
        {recentPosts.map(({ node }) => {
          return <BlogCard key={node.id} blog={node} />
        })}
      </div>
    </div>
  )
}

const getFooterPosts = graphql`
  query getFooterPosts {
    posts: allKontentItemPost(
      sort: { fields: elements___published_date___value, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          elements {
            heading {
              value
            }
            featured_text {
              value
            }
            author {
              value {
                ... on kontent_item_author {
                  id
                  elements {
                    name {
                      value
                    }
                  }
                }
              }
            }
            featured_image {
              value {
                fluid(maxWidth: 1024, quality: 75) {
                  ...KontentAssetFluid_withWebp_noBase64

                }
                description
              }
            }
            published_date {
              value(formatString: "MMMM DD, YYYY")
            }
            slug {
              value
            }
          }
          id
        }
      }
    }
    postsV2: allKontentItemPostCopy(
      sort: { fields: elements___published_date___value, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          elements {
            heading {
              value
            }
            featured_text {
              value
            }
            author {
              value {
                ... on kontent_item_author {
                  id
                  elements {
                    name {
                      value
                    }
                  }
                }
              }
            }
            featured_image {
              value {
                fluid(maxWidth: 1024, quality: 75) {
                  ...KontentAssetFluid_withWebp_noBase64

                }
                description
              }
            }
            published_date {
              value(formatString: "MMMM DD, YYYY")
            }
            slug {
              value
            }
          }
          id
        }
      }
    }
  }
`
export default BlogFooter
